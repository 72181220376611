.product-kit__container {
  margin: 0 auto;
  max-width: #{$container-max-width-l};
  position: relative;
  width: 90%;
  z-index: 1;
  .product-full {
    display: none;
    float: #{$ldirection};
    margin: 0 auto;
    padding-top: 0;
    width: 50%;
    @include breakpoint($landscape-up) {
      display: block;
    }
    &__images {
      float: none;
      min-height: 400px;
      width: 100%;
    }
    &__carousel {
      float: none;
      margin: 0;
      white-space: nowrap;
      width: 100%;
      &-thumbs {
        @include swap_direction(margin, 0 12px 0 0);
        display: inline-block;
        vertical-align: top;
        white-space: normal;
        width: 15%;
      }
      &-thumb {
        @include swap_direction(margin, 0 4px 15px 0);
        cursor: pointer;
        display: block;
        margin-bottom: 15px;
        max-height: 65px;
        max-width: 100%;
        overflow: hidden;
        width: 65px !important; //important used for overriding inline dynamic styles
        &.slick-current {
          @include swap_direction(box-shadow, inset 0 0 0 1px #{$color-black});
          border: 1px solid #{$color-black};
        }
        &-image {
          display: block;
          height: 65px;
          width: 65px;
        }
      }
      &-slides {
        display: inline-block;
        vertical-align: middle;
        width: 75%;
        white-space: normal;
        @include breakpoint($landscape-up) {
          margin-#{$ldirection}: 5%;
        }
      }
      &-slide {
        display: block;
        float: #{$ldirection};
        height: 100%;
        margin: 0;
        min-height: 1px;
        width: 500px;
        &-img {
          display: block;
          height: 420px;
          margin: 0 auto;
          width: 450px;
        }
      }
    }
    &__image-container {
      margin-bottom: 5px;
      @include breakpoint($landscape-up) {
        @include swap_direction(margin, 2% 0 5% 22%);
        width: 75%;
      }
    }
    &__image-thumbnails {
      display: none;
    }
    &__media {
      @include swap_direction(margin, 0 20px 0 65px);
      float: #{$ldirection};
      height: auto;
      min-height: 663px;
      width: 52%;
      @include breakpoint($landscape-up) {
        display: none;
      }
      &-name {
        font-size: get-rem(18px);
        font-weight: bold;
        margin-bottom: 5px;
        text-align: #{$ldirection};
        text-transform: uppercase;
      }
      &-subtitle {
        font-family: #{$font--text-main};
        font-size: get-rem(14px);
        line-height: get-line-height(14px, 18px);
        margin-bottom: 20px;
        text-align: #{$ldirection};
      }
      &-benefit {
        font-size: get-rem(18px);
        text-align: center;
      }
    }
    &__price-wrapper {
      margin: 20px 0;
    }
    &__terms-content {
      font-family: #{$font--text-main};
      font-size: get-rem(14px);
      line-height: get-line-height(14px, 18px);
      margin: 15px 0 60px;
      text-align: #{$ldirection};
    }
  }
  .product-kit__content {
    float: #{$rdirection};
    font-family: #{$font--text-main};
    width: 100%;
    @include breakpoint($landscape-up) {
      width: 49%;
    }
    .product-full {
      &__name {
        font-size: get-rem(22px);
      }
      &__desc {
        font-size: get-rem(14px);
      }
      &__title {
        font-size: get-rem(14px);
        line-height: get-line-height(14px, 18px);
        margin-bottom: 10px;
      }
    }
    .product-kit-menu__item {
      border-top: 1px solid #{$color-dark-gray};
      &:last-child {
        border-bottom: 1px solid #{$color-dark-gray};
      }
      .product-kit__button {
        background: #{$color-dark-gray};
        color: #{$color-black};
        pointer-events: none;
        .add-to-set {
          display: none;
        }
        .sold-out {
          display: block;
        }
      }
      &.all-oos-button-enable {
        .product-kit__button {
          background: #{$color-black};
          color: #{$color-white};
          pointer-events: all;
          .add-to-set {
            display: block;
          }
          .sold-out {
            display: none;
          }
        }
      }
      .product-kit {
        &__title {
          @include swap_direction(margin, 0 0 3px 0);
          @include swap_direction(padding, 18px 0 18px 15px);
          background: url('/media/images/icons/plus.svg') no-repeat 100%;
          cursor: pointer;
          font-size: get-rem(15px);
          position: relative;
          text-transform: uppercase;
          @include breakpoint($landscape-up) {
            padding: 10px 0;
          }
          &-header-content {
            &.product-selected {
              background: url('/media/images/icons/tick.svg') no-repeat 100%;
              background-size: 15px;
              padding-#{$rdirection}: 21px;
            }
          }
          &.selected {
            background: url('/media/images/icons/minus.svg') no-repeat 100%;
          }
        }
        &__price {
          margin-top: 10px;
          .dynamic-pricing__active-per-ml {
            font-family: #{$font--text};
            font-size: get-rem(12px);
          }
        }
        &__image-container {
          float: #{$ldirection};
          font-size: get-rem(14px);
          line-height: get-line-height(14px, 18px);
          margin-bottom: 15px;
          word-wrap: break-word;
          @include breakpoint($landscape-up) {
            height: 150px;
            width: 25%;
          }
          .product-kit__image {
            cursor: pointer;
            height: 68px;
            max-height: 120px;
            width: 68px;
            @include breakpoint($landscape-up) {
              height: 120px;
              width: 120px;
            }
            &.clicked {
              border: 2px solid #{$color-black};
            }
          }
        }
        &__out-of-stock-disabled {
          cursor: default;
          opacity: 0.3;
          .product-kit__image {
            cursor: default;
            &.clicked {
              border: none;
            }
          }
        }
        &__products {
          .slick-prev,
          .slick-next {
            top: 45px;
            &::before {
              background-size: 15px;
            }
          }
          .slick-prev {
            #{$ldirection}: -15px;
          }
          .slick-next {
            #{$rdirection}: -20px;
          }
        }
        &__button {
          margin: 20px 0;
          width: 100%;
          .add-to-set {
            display: block;
            letter-spacing: 1px;
          }
          .added,
          .sold-out {
            display: none;
          }
          &.product-added {
            background: #{$color-white};
            border: 1px solid #{$color-black};
            color: #{$color-black};
            .add-to-set {
              display: none;
            }
            .added {
              display: block;
            }
          }
        }
        &__products-list {
          &.in-active {
            display: none;
          }
        }
      }
      .slick-list {
        margin-#{$ldirection}: 15px;
      }
      .accordion-content,
      .collapsible-sub-menu {
        display: none;
        &.active {
          display: block;
        }
      }
    }
    .product-kit__cta-container {
      border-top: 1px solid #{$color-dark-gray};
      .product-kit__add-to-bag {
        background: #{$color-black};
        box-shadow: none;
        color: #{$color-white};
        height: 43px;
        font-size: get-rem(12px);
        line-height: get-line-height(12px, 24px);
        margin: 20px 0;
        padding: 10px 0;
        width: 100%;
        &.button--disabled {
          background: #{$color-dark-gray};
          color: #{$color-black};
          pointer-events: none;
        }
        .sold-out {
          display: none;
        }
        .product-add-to-bag {
          display: block;
        }
        &.button-inactive {
          .sold-out {
            display: block;
          }
          .product-add-to-bag {
            display: none;
          }
        }
        &.added {
          background: #{$color-black};
          pointer-events: inherit;
        }
      }
    }
  }
  .product-kit__sticky {
    #{$ldirection}: 0;
    background: #{$color-white};
    border-top: 1px solid #{$color-light-gray};
    bottom: 0;
    overflow: hidden;
    position: fixed;
    text-align: #{$ldirection};
    width: 100%;
    z-index: 15;
    .sticky-add-to-bag {
      &--inner {
        @include swap_direction(padding, 0 15px 5px 20px);
        min-height: 70px;
      }
      &__container {
        align-items: center;
        font-weight: bold;
        margin: 0 auto;
        overflow: hidden;
        text-align: #{$ldirection};
        text-transform: uppercase;
        width: 90%;
        .dynamic-pricing__sticky-container {
          float: #{$rdirection};
          padding-top: 30px;
        }
      }
      &__image-single {
        width: 62px;
        display: inline-block;
        .sticky-add-to-bag__image {
          width: 56px;
        }
      }
      &__description-container {
        display: inline-block;
        margin-#{$ldirection}: 30px;
        padding-top: 30px;
        width: 42%;
        .product-full__name,
        .product-full__price {
          display: inline-block;
        }
        .product-full__name {
          font-size: get-rem(18px);
          letter-spacing: 1px;
          margin-#{$rdirection}: 3px;
        }
        .product-full__price {
          font-size: get-rem(16px);
        }
      }
      &__section {
        @include swap_direction(margin, 19px 50px 10px 20px);
        background: #{$color-black};
        color: #{$color-white};
        cursor: pointer;
        float: #{$rdirection};
        height: 41px;
        padding: 16px 40px;
        text-align: center;
        width: auto;
        &.button--disabled {
          background: #{$color-dark-gray};
          color: #{$color-black};
          pointer-events: none;
        }
        .sold-out {
          display: none;
        }
        .add-to-bag-text {
          display: block;
        }
        &.button-inactive {
          .sold-out {
            display: block;
          }
          .add-to-bag-text {
            display: none;
          }
        }
        &.added {
          background: #{$color-black};
          box-shadow: none;
          pointer-events: inherit;
        }
      }
    }
  }
  .product__price {
    &--non-sale,
    &--sale {
      font-family: #{$font--pretendard-semi-bold};
      font-size: get-rem(15px);
      font-weight: bold;
    }
    &--non-sale {
      text-decoration: line-through;
    }
    &--sale {
      color: #{$color-error};
    }
  }
  .kit-pc-hidden {
    display: block;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
}
