.elc-search-active-screen-wrapper {
  .elc-product-brief {
    .elc-product-badge {
      font-family: $font--pretendard-semi-bold;
      @include breakpoint($landscape-up) {
        #{$rdirection}: 0;
        top: 0;
        width: 50px;
        height: 50px;
        font-size: get-rem(11px);
        line-height: get-line-height(11px, 13px);
      }
    }
    .elc-size-picker-wrapper {
      .slick-slide {
        margin-#{$rdirection}: 5px;
        margin-bottom: 10px;
      }
    }
  }
}

.elc-product-reviews {
  .elc-reviews {
    .elc-spp-reviews {
      .pr-review-display {
        .pr-rd-main-header {
          .pr-rd-review-header-contents {
            width: auto;
            clear: both;
          }
          .pr-clearfix {
            clear: none;
          }
          .pr-rd-review-header-sorts {
            &.pr-rd-main-header-sorts-w-search {
              width: 170px;
              margin: 0 auto;
              padding-#{$rdirection}: 10px;
              float: none !important;
              @include breakpoint($landscape-up) {
                width: auto;
                padding-top: 5px;
                float: #{$ldirection} !important;
              }
              .pr-rd-sort {
                margin-top: 7px;
                @include breakpoint($landscape-up) {
                  margin-top: 0;
                  line-height: 1.3;
                }
              }
            }
          }
        }
      }
    }
  }
}

.sd-product-spp {
  &__product-cross-sell {
    .elc-grid-container {
      .slick-list {
        .slick-slide {
          margin: 0 10px;
          @include breakpoint($landscape-up) {
            margin: 15px 20px 0;
          }
        }
      }
    }
  }
}

.p-w-r {
  .pr-question-form {
    #questionType {
      display: none;
    }
  }
}

.pr-media-card:focus {
  box-shadow: 0 0 48px -6px;
}

.site-header-formatter {
  .content-block-signup {
    &__disclaimer {
      color: $color-primary-800;
    }
  }
}

.gift-options-content {
  &__container {
    .giftmessage_count.adpl {
      &__mode-label {
        padding-top: 20px;
      }
    }
    .nojs_hidden {
      padding-#{$ldirection}: 3px;
    }
  }
}

.elc-product-carousel-container {
  .elc-product-brief {
    position: relative;
  }
}
