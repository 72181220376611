// Importing drjart Base PC theme.
@import "../../drjart_base/scss/drjart-base";
@import "all";
// test
.foo { color: inherit } 
// US Specific ApplePay
.pg_wrapper--checkout {
  .apple-pay-button-container {
    border: 0;
    padding: 0;
    margin: 0 0 5px 0;
    .apple-pay-button-white {
      -apple-pay-button-style: black-outline;
      -apple-pay-button-type: check-out;
      height: 50px;
      margin-#{$ldirection}: 0;
    }
  }
}
.enhanced-checkout-page {
  .left {
    .checkout-buttons-content {
      .apple-pay-button-container {
        padding: 0;
        margin-bottom: 5px;
      }
    }
  }
}
